import { z } from "zod";

// generic schemas

export const TemplateGroupSchema = z.enum([
  "back",
  "elbow",
  "fall_prevention",
  "hand",
  "hip",
  "knee",
  "lower_leg",
  "neck",
  "osteoporosis",
  "shoulder",
]);

export const HealthFormTypeSchema = z.enum([
  "fall_prevention",
  "generic",
  "hand_oa",
  "hip_oa",
  "knee_oa",
  "low_back_pain",
  "neck",
  "osteoporosis",
  "shoulder_pain",
  "generic",
]);

export const LevelSchema = z.object({
  about: z.string().nullable(),
  id: z.number(),
  image_url: z.string(),
  instructions_icon_name: z.string().nullable().optional(),
  instructions_text1: z.string().nullable().optional(),
  instructions_text2: z.string().nullable().optional(),
  instructions_text3: z.string().nullable().optional(),
  level: z.number(),
  tip_description: z.string().nullable(),
  title: z.string(),
  variation_title: z.string().nullable(),
  video_file_url: z.string().nullable(),
  video_thumbnail_file_url: z.string().nullable(),
  caption_file_url: z.string().nullable().optional(),
});

const sharedCourseExerciseSchema = z.object({
  end_protocol_week: z.number().nullable(),
  id: z.number(),
  introduction_protocol_week: z.number(),
  premium: z.boolean(),
  target_joints: z.array(z.string()),
  levels: z.array(LevelSchema),
});

export const UserCourseExerciseSchema = sharedCourseExerciseSchema.merge(
  z.object({
    active: z.boolean(),
    current_level: z.number(),
  })
);

export const UserCourseTrimmedExerciseSchema = sharedCourseExerciseSchema.merge(
  z.object({
    active: z.boolean(),
    current_level: z.number().optional(),
  })
);

export const UserCourseTemplateExerciseSchema = sharedCourseExerciseSchema;

export const UserCourseLibraryExerciseSchema = z.object({
  exercise_id: z.number(),
  levels: z.array(LevelSchema),
  premium: z.boolean(),
  target_joints: z.array(z.string()),
});

export const UserCourseLibraryExercisesSchema = z.array(UserCourseLibraryExerciseSchema);

export const UserCourseLessonSchema = z.object({
  id: z.number(),
  image_url: z.string(),
  parts_count: z.number(),
  title: z.string(),
  target_joints: z.array(z.string()),
});

export const UserCourseLibraryLessonsSchema = z.array(UserCourseLessonSchema);

export const UserCourseTemplateSchema = z.object({
  id: z.number(),
  name: z.string().optional().nullable(),
  standardized: z.boolean(),
  group: TemplateGroupSchema.optional().nullable(),
  key: z
    .enum([
      "achilles_tendinopathy",
      "ankle_instability",
      "fall_prevention",
      "foot_oa_or_unspecified",
      "general_elbow_pain",
      "hand_oa",
      "hip_oa",
      "hip_pain_unspecific",
      "knee_instability",
      "knee_oa",
      "lateral_epicondylalgia",
      "low_back_pain",
      "medial_epicondylalgia",
      "neck",
      "osteoporosis",
      "patellofemoral_pain_syndrome",
      "plantar_faschiit",
      "hallux_valgus",
      "post_op_hip",
      "post_op_knee",
      "pre_op_hip",
      "pre_op_knee",
      "shoulder_pain",
      "trochanterit",
      "wrist_pain",
    ])
    .optional()
    .nullable(),
});

export const UserCourseTemplatesSchema = z.array(UserCourseTemplateSchema);

// api schemas

export const GetUserCourseLibaryResponseSchema = z.array(UserCourseLibraryExerciseSchema);

export const GetUserCourseResponseSchema = z.object({
  exercises: z.array(UserCourseExerciseSchema),
  lessons: z.array(UserCourseLessonSchema).optional().nullable(),
  max_exercises_per_day: z.number(),
  strategy: z.string(),
  functional_test: z.boolean(),
  health_form_type: HealthFormTypeSchema,
});

export const GetUserCourseTemplateResponseSchema = z.object({
  exercises: z.array(UserCourseTemplateExerciseSchema),
  lessons: z.array(UserCourseLessonSchema).optional().nullable(),
  max_exercises_per_day: z.number(),
  name: z.string().optional().nullable(),
  standardized: z.boolean(),
  key: z.string().optional().nullable(),
  functional_test: z.boolean(),
  health_form_type: HealthFormTypeSchema,
});

export const PutUserCoursePayloadSchema = z.object({
  exercises: z.array(
    z.object({
      exercise_id: z.number(),
      introduction_protocol_week: z.number(),
      end_protocol_week: z.number().nullable(),
      level_recommendation: z.number().optional(),
    })
  ),
  lessons: z
    .array(
      z.object({
        lesson_id: z.number(),
      })
    )
    .optional()
    .nullable(),
  max_exercises_per_day: z.number(),
  functional_test: z.boolean(),
  health_form_type: HealthFormTypeSchema,
});

export const PutUserCourseArgsSchema = z.object({
  course: PutUserCoursePayloadSchema,
  patientId: z.number(),
});

export const GetUserCourseTemplatesResponseSchema = z.array(UserCourseTemplateSchema);

export const CreateUserCourseTemplatePayloadSchema = z.object({
  exercises: z.array(
    z.object({
      exercise_id: z.number(),
      introduction_protocol_week: z.number(),
      end_protocol_week: z.number().nullable(),
    })
  ),
  max_exercises_per_day: z.number(),
  name: z.string(),
  functional_test: z.boolean(),
  health_form_type: HealthFormTypeSchema,
});

export const CreateUserCourseArgsSchema = z.object({
  course: CreateUserCourseTemplatePayloadSchema,
  therapistId: z.number(),
});

export const UpdateUserCourseTemplatePayloadSchema = z.object({
  exercises: z.array(
    z.object({
      exercise_id: z.number(),
      introduction_protocol_week: z.number(),
      end_protocol_week: z.number().nullable(),
    })
  ),
  lessons: z
    .array(
      z.object({
        lesson_id: z.number(),
      })
    )
    .optional()
    .nullable(),
  max_exercises_per_day: z.number(),
  name: z.string().optional(),
  functional_test: z.boolean(),
  health_form_type: HealthFormTypeSchema,
});

export const UpdateUserCourseArgsSchema = z.object({
  course: UpdateUserCourseTemplatePayloadSchema,
  therapistId: z.number(),
  templateId: z.number(),
});

export const DeleteUserCourseArgsSchema = z.object({
  therapistId: z.number(),
  templateId: z.number(),
});

// types

export type UserCourseExercise = z.infer<typeof UserCourseExerciseSchema>;

export type UserCourseTrimmedExercise = z.infer<typeof UserCourseTrimmedExerciseSchema>;

export type UserCourseTemplateExercise = z.infer<typeof UserCourseTemplateExerciseSchema>;

export type UserCourseLesson = z.infer<typeof UserCourseLessonSchema>;

export type UserCourseLibraryExercise = z.infer<typeof UserCourseLibraryExerciseSchema>;

export type UserCourseLibraryExercises = z.infer<typeof UserCourseLibraryExercisesSchema>;

export type UserCourseLibraryLessons = z.infer<typeof UserCourseLibraryLessonsSchema>;

export type UserCourseTemplate = z.infer<typeof UserCourseTemplateSchema>;

export type UserCourseTemplates = z.infer<typeof UserCourseTemplatesSchema>;

export type GetUserCourseResponse = z.infer<typeof GetUserCourseResponseSchema>;

export type GetUserCourseTemplateResponse = z.infer<typeof GetUserCourseTemplateResponseSchema>;

export type PutUserCoursePayload = z.infer<typeof PutUserCoursePayloadSchema>;

export type PutUserCourseArgs = z.infer<typeof PutUserCourseArgsSchema>;

export type GetUserCourseTemplatesResponse = z.infer<typeof GetUserCourseTemplatesResponseSchema>;

export type CreateUserCourseTemplatePayload = z.infer<typeof CreateUserCourseTemplatePayloadSchema>;

export type UpdateUserCourseTemplatePayload = z.infer<typeof UpdateUserCourseTemplatePayloadSchema>;

export type CreateUserCourseArgs = z.infer<typeof CreateUserCourseArgsSchema>;

export type UpdateUserCourseArgs = z.infer<typeof UpdateUserCourseArgsSchema>;

export type DeleteUserCourseArgs = z.infer<typeof DeleteUserCourseArgsSchema>;

export type HealthFormType = z.infer<typeof HealthFormTypeSchema>;

export type TemplateGroup = z.infer<typeof TemplateGroupSchema>;
