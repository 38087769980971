import type React from "react";
import { useContext, useEffect, useState } from "react";

import { faCirclePlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { UserCourseTemplate, UserCourseTemplates } from "api/schemas/UserCourse";
import type { Joint } from "api/types/GetActivityLibraryEntriesArgs";
import {
  CancelRoundIcon,
  JaBack,
  JaCalf,
  JaElbow,
  JaFallPrevention,
  JaFoot,
  JaHand,
  JaHip,
  JaKnee,
  JaNeck,
  JaOsteoporosis,
  JaShoulder,
  JaWrist,
} from "assets";
import IconButton from "shared/atoms/IconButton";
import { CurrentPatientContext } from "utils/contexts";

import RadioButtons from "./RadioButtons";
import ResponsivePopup from "./ResponsivePopup";
import { SmallPrimaryButton } from "./SharedStyledComponents";

const Templates = ({
  onOpenExercisePicker,
  templates = [],
  onChangeTemplate,
  onDeleteTemplate,
}: {
  onOpenExercisePicker: () => void;
  templates?: UserCourseTemplates;
  onChangeTemplate: (id: number) => void;
  onDeleteTemplate: (id: number) => void;
}) => {
  const { t, i18n } = useTranslation();
  const [showDeleteConfirmationPopup, setShowDeleteConfirmationPopup] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<null | number>(null);
  const [selectedGroup, setSelectedGroup] = useState("all");
  const { patient } = useContext(CurrentPatientContext);
  const { location: jointLocation } = patient?.most_painful_location ?? {};
  const ailment = patient?.ailment;

  useEffect(() => {
    if (ailment) {
      switch (ailment) {
        case "osteoporosis": {
          setSelectedGroup("osteoporosis");
          break;
        }
        case "fall_prevention": {
          setSelectedGroup("fall_prevention");
          break;
        }
        default: {
          setSelectedGroup("all");
          break;
        }
      }
    }

    if (jointLocation) {
      switch (jointLocation as Joint) {
        case "knee": {
          setSelectedGroup("knee");
          break;
        }
        case "hip": {
          setSelectedGroup("hip");
          break;
        }
        case "foot":
        case "lower_leg": {
          setSelectedGroup("lower_leg");
          break;
        }
        case "wrist":
        case "hand": {
          setSelectedGroup("hand");
          break;
        }
        case "elbow": {
          setSelectedGroup("elbow");
          break;
        }
        case "shoulder": {
          setSelectedGroup("shoulder");
          break;
        }
        case "neck": {
          setSelectedGroup("neck");
          break;
        }
        case "upper_back":
        case "lower_back": {
          setSelectedGroup("back");
          break;
        }
        default: {
          setSelectedGroup("all");
        }
      }
    }
  }, [jointLocation]);

  const getKeyName = (key: string) => {
    if (i18n.exists(`patients.pain_location.${key}.0`)) {
      // FIXME: type translation
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return String(t(`patients.pain_location.${key}.0`));
    }
    if (i18n.exists(`patients.ailments.${key}`)) {
      // FIXME: type translation
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return String(t(`patients.ailments.${key}`));
    }
    return key;
  };

  const renderTemplate = (tmp: UserCourseTemplate) => {
    const name = tmp.key ? t(`usercourse.templates.standardized.name.${tmp.key}`) : tmp.name || String(tmp.id);

    return (
      <TemplateItem
        key={tmp.id}
        name={name}
        imageComponent={tmp.key ? <JointImage id={tmp.key} /> : undefined}
        deletable={!tmp.standardized}
        onClick={() => {
          onChangeTemplate(tmp.id);
        }}
        onDelete={() => {
          setSelectedTemplate(tmp.id);
          setShowDeleteConfirmationPopup(true);
        }}
      />
    );
  };

  const standardizedTemplates = templates.filter(tmp => tmp.standardized);
  const customTemplates = templates.filter(tmp => !tmp.standardized);

  const groupedTemplates = templates.reduce((acc, template) => {
    if (template.group) {
      if (acc[template.group]) {
        acc[template.group].push(template);
      } else {
        acc[template.group] = [template];
      }
    }
    return acc;
  }, {} as Record<string, UserCourseTemplate[]>);

  const groupOptions = Object.keys(groupedTemplates)
    .sort((a, b) => {
      return String(getKeyName(a)).localeCompare(getKeyName(b));
    })
    .map(key => ({
      // FIXME: type translation
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      label: getKeyName(key),
      value: key,
    }));

  const allGroupOptions = [{ label: t("usercourse.exerciselist.all"), value: "all" }, ...groupOptions];

  return (
    <Wrapper>
      <OuterMargin>
        <Title>{t("usercourse.templates.buildnew.description")}</Title>
        <TemplatesGrid>
          <TemplateItem
            onClick={() => {
              onOpenExercisePicker();
            }}
            imageComponent={
              <CenterIcon>
                <FontAwesomeIcon icon={faCirclePlus} />
              </CenterIcon>
            }
            name={t("usercourse.add_exercise")}
            deletable={false}
            onDelete={() => {
              //
            }}
          />
        </TemplatesGrid>
        <GridWrapper>
          <Title>{t("usercourse.templates.standardized.description")}</Title>
          <RadioButtons
            options={allGroupOptions}
            value={selectedGroup}
            onChange={group => {
              setSelectedGroup(group);
            }}
          />
        </GridWrapper>
        {selectedGroup !== "all" && groupedTemplates[selectedGroup] && (
          <TemplatesGrid>{groupedTemplates[selectedGroup].map(renderTemplate)}</TemplatesGrid>
        )}
        {selectedGroup === "all" && <TemplatesGrid>{standardizedTemplates.map(renderTemplate)}</TemplatesGrid>}

        {!!customTemplates.length && (
          <GridWrapper>
            <Title>{t("usercourse.templates.custom.description")}</Title>
            <TemplatesGrid>{customTemplates.map(renderTemplate)}</TemplatesGrid>
          </GridWrapper>
        )}
      </OuterMargin>
      {showDeleteConfirmationPopup && (
        <ResponsivePopup
          onClose={() => {
            setShowDeleteConfirmationPopup(false);
          }}
          open
        >
          <PopupRoot>
            <Header>{t("usercourse.templates.delete.cta")}</Header>
            <div>
              <div>
                {t("usercourse.templates.delete.body1", {
                  name: templates.find(tmp => tmp.id === selectedTemplate)?.name,
                })}
              </div>
              <div>{t("usercourse.templates.delete.body2")}</div>
            </div>
            <div>
              <SmallPrimaryButton
                onClick={() => {
                  if (selectedTemplate !== null) {
                    onDeleteTemplate(selectedTemplate);
                    setSelectedTemplate(null);
                    setShowDeleteConfirmationPopup(false);
                  }
                }}
              >
                {t("usercourse.templates.delete.cta")}
              </SmallPrimaryButton>
            </div>
          </PopupRoot>
        </ResponsivePopup>
      )}
    </Wrapper>
  );
};

export default Templates;

const Wrapper = styled.div`
  margin-bottom: ${props => props.theme.spacing.S_20};
  white-space: pre-wrap;
  ${props => props.theme.font.body1}
  color: ${props => props.theme.colors.greys.dark};
  padding: 32px;
`;

const JointImage = ({ id }: { id: string }) => {
  switch (id) {
    case "lower_leg":
    case "achilles_tendinopathy": {
      return <JaCalf />;
    }
    case "wrist":
    case "wrist_pain": {
      return <JaWrist />;
    }
    case "foot":
    case "plantar_faschiit":
    case "hallux_valgus":
    case "foot_oa_or_unspecified":
    case "ankle_instability": {
      return <JaFoot />;
    }
    case "back":
    case "low_back_pain": {
      return <JaBack />;
    }
    case "hand":
    case "hand_oa": {
      return <JaHand />;
    }
    case "neck": {
      return <JaNeck />;
    }
    case "shoulder":
    case "shoulder_pain": {
      return <JaShoulder />;
    }
    case "hip":
    case "trochanterit":
    case "hip_pain_unspecific":
    case "post_op_hip":
    case "pre_op_hip":
    case "hip_and_knee_oa":
    case "hip_oa": {
      return <JaHip />;
    }
    case "knee":
    case "patellofemoral_pain_syndrome":
    case "knee_instability":
    case "post_op_knee":
    case "pre_op_knee":
    case "knee_oa": {
      return <JaKnee />;
    }
    case "elbow":
    case "general_elbow_pain":
    case "lateral_epicondylalgia":
    case "medial_epicondylalgia": {
      return <JaElbow />;
    }
    case "osteoporosis": {
      return <JaOsteoporosis />;
    }
    case "fall_prevention": {
      return <JaFallPrevention />;
    }
    default: {
      return <></>;
    }
  }
};

const TemplateItem = ({
  name,
  imageComponent,
  deletable,
  onDelete,
  onClick,
}: {
  name: string;
  imageComponent: React.ReactNode;
  deletable: boolean;
  onClick: () => void;
  onDelete: () => void;
}) => {
  return (
    <TemplateItemWrapper onClick={() => onClick()}>
      <div>{name}</div>
      <JointImageWrapper>{imageComponent}</JointImageWrapper>
      {deletable && (
        <DeleteButton>
          <IconButton
            label=""
            onClick={e => {
              e.stopPropagation();
              onDelete();
            }}
          >
            <CancelRoundIcon />
          </IconButton>
        </DeleteButton>
      )}
    </TemplateItemWrapper>
  );
};

const TemplateItemWrapper = styled.div`
  cursor: pointer;
  width: 221px;
  min-height: 68px;
  box-sizing: border-box;
  padding: 8px 18px;
  border-radius: 8px;
  font-size: 16px;
  color: ${props => props.theme.colors.redesign.b100};
  font-weight: 400;
  border: 1px solid #e1e8ef;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    border-color: ${({ theme }) => theme.colors.redesign.b100};
    background-color: ${({ theme }) => theme.colors.redesign.b10};
  }
  ${props => props.theme.belowBreakpoint} {
    width: 100%;
  }
`;

const GridWrapper = styled.div`
  padding: 42px 0 8px 0;
`;

const Title = styled.div`
  font-size: 16px;
  color: #273546;
  font-weight: 600;
  margin-bottom: 16px;
`;

const Header = styled.div`
  font-size: 22px;
  font-weight: bold;
`;

const TemplatesGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const DeleteButton = styled.div`
  height: 48px;
  display: flex;
`;

const CenterIcon = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
`;

const PopupRoot = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
`;

export const OuterMargin = styled.div`
  margin: 0 auto;
  max-width: 932px;
`;

const JointImageWrapper = styled.div`
  height: 48px;
`;
